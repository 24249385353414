import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/header";
import { handleGetCouponbyId } from "../shared/api";
import { API_URL } from "../constant";
import CustomLoader from "./CustomeLoader";
const ViewCoupon = () => {
    const { id } = useParams()
    const [loader, setloader] = useState(true);
    const [couponData, setcouponData] = useState({
        name: null,
        image: null,
        expiration: null,
        redemption: null,
        nftid: null,
        status: null,
    });
    useEffect(() => {
        if (id) {
            handleGetCouponbyId(id).then((res) => {
                const data = res.data.data;
                setcouponData({
                    name: data.name,
                    image: data.image,
                    expiration: data.expirationDate.split("T")[0],
                    redemption: data.redemptionDate.split("T")[0],
                    nftid: data.nFtName,
                    status: data.status,
                })
                setloader(false)

            }).catch((err) => {
                setloader(false)
            })
        }

    }, [id])
    return (
        <>
            <CustomLoader isLoading={loader} />

            <Sidebar />
            {/* <CustomLoader isLoading={loader} /> */}
            <main className="dasboard-main">
                <div className="dashboard-inner-pnl">
                    <Header />
                    <div className="head-inner-panel">
                        <div className="full-div">

                        </div>
                        <div className="full-div">
                            <h1>View Coupon</h1>
                            <div className="spacer-20"></div>
                        </div>
                        <div className="full-div">

                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Coupon Name</Form.Label>
                                <Form.Control

                                    readOnly
                                    value={couponData?.name}
                                    type="text"
                                    placeholder="Enter Coupon Name"
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Photo</Form.Label>
                                <div className="input-wrapper">

                                    <img
                                        style={{ height: "200px", width: "200px" }}
                                        src={`${API_URL}${couponData.image}`}
                                        alt="logofig"
                                    />
                                </div>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>expiration date </Form.Label>
                                <Form.Control
                                    readOnly
                                    type="date"
                                    value={couponData?.expiration}
                                    placeholder="expiration date"
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Redemption date </Form.Label>
                                <Form.Control
                                    readOnly
                                    value={couponData.redemption}
                                    type="date"
                                    placeholder="expiration date"
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>NFT </Form.Label>
                                <Form.Control
                                    readOnly
                                    value={couponData.nftid}
                                    type="text"
                                    placeholder="expiration date"
                                />
                            </Form.Group>
                        </div>
                    </div>
                </div>
            </main>
        </>

    )
}

export default ViewCoupon