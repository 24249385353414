import React from 'react'
export const HeartCount = ({nftData})=><>
{nftData?.isFavourite ?
             <i
               // onClick={() => {
               //   UnLikeNFT(nftData?.id)
               // }}
               className="fa fa-heart red-heart" ></i>
             : 
             <i
               // onClick={() => {
               //   LikeNFT(nftData?.id)
               // }} 
               className="fa fa-heart-o  " ></i>
            }

           <span className="heart-count">
             {' '}  {nftData?.nftFavouritesCount}
             
           </span></>