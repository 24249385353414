import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Breadcrumb, Button } from "react-bootstrap";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/header";
import { API_URL } from "../constant";
import axios from "axios";
import { toast } from "react-toastify";
import CustomLoader from "../Components/CustomeLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { handleGetUserById } from "../shared/api";

const ViewSubAdmin = () => {
    const [loader, setloader] = useState(true)
    const { id } = useParams()
    const [userData, setuserData] = useState({
        firstName: null,
        lastName: null,
        email: null,
        role: null,
        password: null,
        accountType: null,
    });
    useEffect(() => {
        if (id) {
            handleGetUserById(id)
                .then((res) => {
                    let data = res.data.data;
                    setuserData({
                        firstName: data.firstName,
                        lastName: data.lastName,
                        email: data.email,
                        role: data.roleName,
                        password: data.password,
                    });
                    setloader(false);
                })
                .catch((erro) => {
                    setloader(false);
                });
        }
    }, [id])

    return (
        <>
            <CustomLoader isLoading={loader} />
            <Sidebar />
            <main className="dasboard-main">
                <div className="dashboard-inner-pnl">
                    <Header />
                    <div className="head-inner-panel">
                        <div className="full-div">

                        </div>
                        <div className="full-div">
                            <h1>View </h1>
                            <div className="spacer-20"></div>
                        </div>
                        <div className="full-div">
                            <Form

                                style={{ maxWidth: "900px" }}
                            >
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>FIRST NAME</Form.Label>
                                    <Form.Control
                                        readOnly
                                        value={userData.firstName}
                                        name="firstName"
                                        type="text"
                                        placeholder="Enter First name"
                                    />

                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>LAST NAME</Form.Label>
                                    <Form.Control
                                        readOnly
                                        value={userData.lastName}
                                        name="lastName"
                                        type="text"
                                        placeholder="Enter Last name"
                                    />

                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>EMAIL</Form.Label>
                                    <Form.Control
                                        readOnly
                                        value={userData.email}
                                        name="email"
                                        type="email"
                                        placeholder="Enter Email"
                                    />

                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Role</Form.Label>
                                    <Form.Control
                                        readOnly
                                        value={userData.role}
                                        name="email"
                                        type="email"
                                        placeholder="Enter Email"
                                    />

                                </Form.Group>

                                
                                <Form.Group className="mb-5" controlId="formBasicPassword">
                                    <Form.Label>Password</Form.Label>

                                    <div className="button-input-container">
                                        <Form.Control
                                            className="mb-3"
                                            value={userData.password}
                                            type="text"
                                            placeholder="Password"
                                        />

                                    </div>

                                </Form.Group>
                               

                            </Form>
                        </div>
                    </div>
                </div>

            </main>
        </>
    )
}

export default ViewSubAdmin