import { createSlice } from '@reduxjs/toolkit';


const initalState = {
  
    rightAccess: [],
  };

  const rightSlice = createSlice({
    name: "rights",
    initialState: initalState,

    reducers:{
        givenRights: (state, {payload})=>{
            state.rightAccess = payload
        }
    }
  })

  export const {givenRights} = rightSlice.actions
  export const rightSelector = (state)=>state.rights

  export default rightSlice.reducer