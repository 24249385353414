import axios from 'axios';
import { API_URL } from "./constant";
import localforage from "localforage";
import { createBrowserHistory } from 'history';
const history = createBrowserHistory();

const axiosApi = axios.create({baseURL: API_URL});


// config.headers['Authorization'] =`Bearer ${localStorage.getItem("Token")};


axiosApi.interceptors.request.use(
  (config) => {
    
    config.headers['Authorization'] =`Bearer ${localStorage.getItem("Token")}`;
    
    return config;
  },
  (error) => {
    
    return Promise.reject(error);
  }
);




axiosApi.interceptors.response.use(
  (response) => response,
  (error) => {
    
    if (error?.response?.data?.error?.errorMessage === 'Invalid jwt token') {
      // if (error?.response?.data?.error?.errorMessage === 'Unhandled error') {
      history.push('/');
      localStorage.clear();
      localforage.clear();

      window.location.reload()
      
    }
    return Promise.reject(error);
  }
);

export default axiosApi;
