import React, { useState, useEffect } from "react";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import { Button, Form, ListGroup, Modal } from "react-bootstrap";
import { API_URLs, colors, Node_BE_URL } from "../constant";
import InfiniteScrollList from "../Components/InfiniteScrollList";
import InfiniteScroll from "react-infinite-scroll-component";


const ProductModal = ({ isOpen, onRequestClose, onSelectProduct }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [productDetails, setProductDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [page, setpage] = useState(1);
  const handleClose = () => onRequestClose(false);
  const handleShow = () => setShow(true);

  const GetListsData = async (Page) => {
    setLoading(true);
    axios.get(`${Node_BE_URL}/api/getProducts`).then((res) => {
      setData(res?.data?.data)
      setLoading(false)
    }).catch(() => {

    })
  }
  useEffect(() => {
    if (!searchQuery) {
      GetListsData(1)
      setpage(1)
    }
  }, [searchQuery]);
  const fetchProductDetails = async (page = 1) => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URLs}?name=${searchQuery}&page=${page}&limit=15`);
      setProductDetails(prevDetails => page === 1 ? response?.data?.data || [] : [...prevDetails, ...(response?.data?.data || [])]);
      setTotalPages(response?.data?.totalPages || 1);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchQuery) {
      fetchProductDetails(currentPage);
    }
  }, [searchQuery, currentPage]);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    setCurrentPage(1); 
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };
  const nextData = () => {
    const temp = page;
    GetListsData(temp + 1)
    setpage(page + 1)
  }
  function CrossIcon() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    );
  }

  return (
    <>
    
                    <Modal
                    show={isOpen}
                    onHide={handleClose}
                    animation={true}
                    centered
                    style={{ minWidth: '100%' }}
                  >
                    <Modal.Header className="modal-header-color">
                      <Modal.Title className="" style={{ color: "#AD79F7" }}>
                        Product list
                      </Modal.Title>
                      <div
                        className="cross-modal"
                        onClick={
                          handleClose                        }
                      >
                        <CrossIcon />
                      </div>
  
                      
                    </Modal.Header>
                    <Modal.Body className="modal-body-color">
                      <div className="search-pnl mb-4">
                      <div className="search-pnl mb-4">
                      <Form.Group className="mb-3">
                      <div className="input-group">

              <Form.Control
                type="text"
                placeholder="Search Product"
                value={searchQuery}
                onChange={handleSearchChange}
                // className="product-search-input"
                className="form-control"

              />
               <div className="input-group-append">
                    <Button className="reg-btn" onClick={handleSearchChange}>
                        <i className="fa fa-search"></i>
                    </Button>
                </div>
              </div>
              </Form.Group> 
              </div>
              </div>
        {loading && 
          <ClipLoader
          color={'#ac7af7'} 
          loading={loading}
          alignContent= 'center'
          size={50}
          aria-label="Loading Spinner"
          data-testid="loader"
        />}
        <div id="scrollableDiv" style={{ maxHeight: '500px', overflowY: 'scroll' }}>
        <InfiniteScroll
          dataLength={data?.length}
          next={!searchQuery && nextData}
          scrollableTarget="scrollableDiv"
          loader={loading && <div className='loader-style'>
            <ClipLoader
              color={colors.mainColor}
              loading={loading}
              size={30}
            />
          </div>
          }
        >
         <ListGroup variant="flush">
         {productDetails.map((product) => (
              <>
              <ListGroup.Item
              key={product.id}
              style={{ maxHeight: product?.name?.length > 54 ? '100px' : '75px' }}
                action
                onClick={() => {
                  onSelectProduct(product);
                  onRequestClose();
                }}
              >
                {product?.name} {/* Renders the item's name */}

                {/* Renders item's SKU if it exists */}
                {product?.sku && (
                  <p id='para'>
                    {product?.sku} {`(${product?.companyid})`}{" "}
                    {product?.location_name}
                  </p>
                )}


            
              </ListGroup.Item>
              
            </>
            ))}

          </ListGroup>
          </InfiniteScroll>
          </div>
        
       
                    </Modal.Body>
                    
                  </Modal>
                  </>
  );
};

export default ProductModal;
