import React, { useContext } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import OGBXLoader from "../assets/images/Loader.gif"
function CustomLoader({ isLoading }) {

  return (
    <LoadingOverlay
      active={isLoading}
      spinner={<img src={OGBXLoader}></img>}

      styles={{
        wrapper: {
          position: 'fixed',
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          overflow: isLoading ? 'hidden' : 'unset',
          zIndex: isLoading ? 800 : -1
        }
      }}
    >
    </LoadingOverlay >
  )
}
export default CustomLoader;