import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import { A4_Size, downloadSizes, previewSizes, REMOVE_BG } from "../constant";
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
import { Button } from "react-bootstrap";
import ASizeModal from "./ASizeModal";
import A4Modl from "./A4Modl";
import jsPDF from "jspdf";
import CleanBG from "../SVG/CleanBG";
import MessageSvg from "../SVG/MessageSvg";
import Starssvg from "../SVG/Starssvg";

const PdfScreens = ({
  background,
  title,
  titleColor,
  titleFontSize,
  priceFontSize,
  footer,
  pdfProduct,
  regularPrice,
  pdfProductDetails,
  showFooterInput,
  pdfbackground,
  pdfPriceColor,
  showTC,
  pdfcustomPrice,
  useUploadedBackground,
  nameFontSize,
  titleFontFamily,
  customPrice,
  description,
  nameColor,
  priceColor,
}) => {
  console.log(pdfPriceColor, "pdfPriceColor");
  const [processedImage, setProcessedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const imgRefs = useRef([]);

  useEffect(() => {
    const removeBackground = async () => {
      setLoading(true);
      try {
        const imageUrl =
          pdfProductDetails[0]?.imageurl instanceof Blob
            ? URL.createObjectURL(pdfProductDetails[0]?.imageurl)
            : pdfProductDetails[0]?.imageurl;

        const response = await axios.get(REMOVE_BG, {
          params: { url: imageUrl },
          responseType: "blob",
        });
        const url = URL.createObjectURL(response.data);
        setProcessedImage(url);
      } catch (error) {
        toast.error("Image Not Found");
      } finally {
        setLoading(false);
      }
    };

    if (pdfProductDetails && pdfProductDetails[0]?.imageurl) {
      removeBackground();
    }
  }, [pdfProductDetails]);

  const downloadBannersAsPDF = async () => {
    const doc = new jsPDF("portrait", "pt", [793.7, 1122.52]);

    // Capture the first banner (A4_Size)
    const a4SizeBanner = imgRefs.current[5];
    const a4Canvas = await html2canvas(a4SizeBanner);
    const a4ImgData = a4Canvas.toDataURL("image/png");
    doc.addImage(a4ImgData, "PNG", 0, 0, 793.7, 1122.52);

    // Add a page for the second banner
    doc.addPage();

    // Capture the second banner
    const a4SizeBanner2 = imgRefs.current[6];
    const a4Canvas2 = await html2canvas(a4SizeBanner2);
    const a4ImgData2 = a4Canvas2.toDataURL("image/png");
    doc.addImage(a4ImgData2, "PNG", 0, 0, 793.7, 1122.52);

    doc.save("banners.pdf");
  };

  const downloadBannersAsImages = async () => {
    // Download the first banner (A4_Size) as image
    const a4SizeBanner = imgRefs.current[5];
    const a4Canvas = await html2canvas(a4SizeBanner);
    const a4ImgData = a4Canvas.toDataURL("image/png");
    saveAs(a4ImgData, "banner_A4_Size.png");

    // Download the second banner (A4Size) as image
    const a4SizeBanner2 = imgRefs.current[6];
    const a4Canvas2 = await html2canvas(a4SizeBanner2);
    const a4ImgData2 = a4Canvas2.toDataURL("image/png");
    saveAs(a4ImgData2, "banner_A4Size.png");
  };

  const [currentBannerType, setCurrentBannerType] = useState("");

  const handleBannerClick = (bannerType) => {
    setCurrentBannerType(bannerType);
    setShowModal(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // const getBackgroundUrl = () => {
  //   if (useUploadedBackground && background) {
  //     return background instanceof Blob
  //       ? URL.createObjectURL(background)
  //       : background;
  //   }
  //   return CleanBG;
  // };

  return (
    <>
      <div className="scrollable-form-container-live">
        <h3 className="banner-header">- A4 Size</h3>
        <div
          className="banner-preview greenline-mobile"
          style={{
            position: "relative",
            ...previewSizes.A4_Size,
          }}
          ref={(el) => (imgRefs.current[5] = el)}
          data-banner-type="A4_Size"
          onClick={() => handleBannerClick("A4_Size")}
        >
          <div>
            <img
              src={
                useUploadedBackground && pdfbackground
                  ? pdfbackground instanceof Blob
                    ? URL.createObjectURL(pdfbackground)
                    : background
                  : CleanBG
              }
              alt=""
              className="live-bnr"
              // crossOrigin="anonymous"
            />
          </div>

          <div className="banner-content-a4">
            {title && (
              <div
                className="live-title-a4"
                style={{
                  fontSize: titleFontSize,
                  color: titleColor,
                  fontFamily: titleFontFamily,
                }}
              >
                {title}
              </div>
            )}

            {loading ? (
              <div className="loading-styling-pdf">
                <ClipLoader
                  color={"black"}
                  loading={loading}
                  alignContent="center"
                  size={50}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : (
              <>
                <div className="a4-productContainer">
                  {pdfProductDetails?.map((product, index) => (
                    <div key={index}>
                      {product.name && (
                        <div
                          className="a4-nameTag"
                          style={{
                            fontSize: nameFontSize,
                            color: nameColor,
                            fontFamily: titleFontFamily,
                          }}
                        >
                          {product.name}
                        </div>
                      )}

                      {(customPrice || product.price) && (
                        <div
                          className="a4-priceTag"
                          style={{
                            color: pdfPriceColor,
                            fontFamily: titleFontFamily,
                          }}
                        >
                          <p>Regular Price</p>
                          <div className="price-wrapper">
                            <div className="price-slash"></div>
                            <div className="price">${regularPrice}</div>
                          </div>
                          <p>Only for Today</p>${customPrice || product.price}
                        </div>
                      )}
                    </div>
                  ))}

                  {/* Description outside the product container */}
                  {pdfProductDetails?.map(
                    (product, index) =>
                      description && (
                        <div
                          key={`description-${index}`}
                          className="a4-description"
                          style={{
                            fontFamily: titleFontFamily,
                          }}
                        >
                          {description}
                        </div>
                      )
                  )}

                  <div className="msgSVG">
                    <MessageSvg />
                  </div>
                </div>

                {processedImage && (
                  <div>
                    <img
                      src={processedImage}
                      alt="Product"
                      className="a4-transparent-image"
                    />
                  </div>
                )}
              </>
            )}

            {(footer || showFooterInput) && (
              <div
                className="footer-style-a4"
                style={{
                  fontFamily: titleFontFamily,
                }}
              >
                {footer}
              </div>
            )}
          </div>
        </div>

        {/* PDF Section */}
        <h3 className="banner-header">- PDF Screen</h3>
        <div
          className="banner-preview greenline-mobile"
          style={{
            position: "relative",
            ...previewSizes.A4Size,
          }}
          ref={(el) => (imgRefs.current[6] = el)}
          data-banner-type="A4Size"
          onClick={() => handleBannerClick("A4Size")}
        >
          <div>
            <img
              src={
                useUploadedBackground && pdfbackground
                  ? pdfbackground instanceof Blob
                    ? URL.createObjectURL(pdfbackground)
                    : background
                  : CleanBG
              }
              alt=""
              className="live-bnr"
              crossOrigin="anonymous"
            />
          </div>

          <div className="banner-content-a44">
            {title && (
              <div
                className="live-title-a44"
                style={{
                  fontSize: titleFontSize,
                  color: titleColor,
                  fontFamily: titleFontFamily,
                }}
              >
                {title}
              </div>
            )}

            {loading ? (
              <div className="loading-styling-pdf">
                <ClipLoader
                  color={"black"}
                  loading={loading}
                  alignContent="center"
                  size={50}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : (
              <>
                <div className="a44-productContainer">
                  {pdfProductDetails?.map((product, index) => (
                    <div key={index}>
                      {product.name && (
                        <div
                          className="a44-nameTag"
                          style={{
                            fontSize: nameFontSize,
                            color: nameColor,
                            fontFamily: titleFontFamily,
                          }}
                        >
                          {product.name}
                        </div>
                      )}

                      {(customPrice || product.price) && (
                        <div
                          className="a44-priceTag"
                          style={{
                            color: pdfPriceColor,
                            fontFamily: titleFontFamily,
                          }}
                        >
                          <p>Regular Price</p>
                          <div class="price-wrapper">
                            <div class="price-slash"></div>
                            <div class="price">${regularPrice}</div>
                          </div>
                          <p>Only for Today</p>${customPrice || product.price}
                        </div>
                      )}
                    </div>
                  ))}

                  {/* Description outside the product container */}
                  {pdfProductDetails?.map(
                    (product, index) =>
                      description && (
                        <div
                          key={`description-${index}`}
                          className="a4-description"
                          style={{
                            fontFamily: titleFontFamily,
                          }}
                        >
                          {description}
                        </div>
                      )
                  )}
                  <div className="msgSVG">
                    <Starssvg />
                  </div>
                </div>

                {processedImage && (
                  <div>
                    <img
                      src={processedImage}
                      alt="Product"
                      className="a44-transparent-image"
                    />
                  </div>
                )}
              </>
            )}



            {(footer || showFooterInput) && (
              <div
                className="footer-style-a44"
                style={{
                  color: titleColor,
                  fontFamily: titleFontFamily,
                }}
              >
                {footer}
              </div>
            )}
          </div>
        </div>

        {/* Modals */}
        {showModal && (
          <div className="modal-container">
            {currentBannerType === "A4_Size" ? (
              <ASizeModal
                show={showModal}
                handleClose={handleCloseModal}
                {...{
                  background,
                  title,
                  titleColor,
                  titleFontSize,
                  priceFontSize,
                  footer,
                  regularPrice,
                  pdfbackground,
                  useUploadedBackground,
                  description,
                  pdfPriceColor,
                  processedImage,
                  showFooterInput,
                  showTC,
                  nameFontSize,
                  pdfProductDetails,
                  titleFontFamily,
                  customPrice,
                  nameColor,
                  priceColor,
                  bannerType: currentBannerType,
                }}
              />
            ) : currentBannerType === "A4Size" ? (
              <A4Modl
                show={showModal}
                handleClose={handleCloseModal}
                {...{
                  background,
                  title,
                  titleColor,
                  titleFontSize,
                  priceFontSize,
                  footer,
                  regularPrice,
                  useUploadedBackground,
                  description,
                  processedImage,
                  pdfbackground,
                  showFooterInput,
                  pdfPriceColor,
                  showTC,
                  nameFontSize,
                  pdfProductDetails,
                  titleFontFamily,
                  customPrice,
                  nameColor,
                  priceColor,
                  bannerType: currentBannerType,
                }}
              />
            ) : null}
          </div>
        )}
      </div>

      <div className="buttons-container">
        <Button
          className="reg-btn mb-3 big block button-border"
          onClick={downloadBannersAsPDF}
        >
          Download as PDF
        </Button>

        <Button
          className="reg-btn mb-3 big block button-border"
          onClick={downloadBannersAsImages}
        >
          Download as Images
        </Button>
      </div>
    </>
  );
};

export default PdfScreens;
