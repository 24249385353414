import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Breadcrumb, Button } from "react-bootstrap";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/header";
import { API_URL } from "../constant";
import axios from "axios";
import { toast } from "react-toastify";
import CustomLoader from "../Components/CustomeLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
const strongPasswordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

const accountType = [
  {
    name: "Admin ",
    value: 1,
  },
  {
    name: "SubAdmin  ",
    value: 2,
  },
  {
    name: "User ",
    value: 3,
  },
];
function AddSubadmin() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [role, setrole] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setloader] = useState(false);
  const [error, setError] = useState({
    firstName: null,
    lastName: null,
    email: null,
    role: null,
    password: null,
  });
  const [userAccountType, setUserAccountType] = useState();
  useEffect(() => {
    setUserAccountType(localStorage.getItem('accountType'))
    axios
      .get(API_URL + `api/SuperAdmin/getroles`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
        },
      })
      .then((res) => {
        setrole(res.data.data);
      })
      .catch(() => { });
  }, []);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setloader(true);

    let temp = 0;
    const form = e.target;
    const formData = new FormData(form);
    const formValues = Object.fromEntries(formData.entries());
    if (!formValues.firstName) {
      setError((prevState) => ({
        ...prevState,
        firstName: "First name is required",
      }));
      temp++;
    }
    if (!formValues.lastName) {
      setError((prevState) => ({
        ...prevState,
        lastName: "Last name is required",
      }));
      temp++;
    }
    if (!formValues.email) {
      setError((prevState) => ({ ...prevState, email: "email is required" }));
      temp++;
    }
    if (!formValues.role) {
      setError((prevState) => ({ ...prevState, role: "Role is required" }));
      temp++;
    }
    if (!formValues.password) {
      setError((prevState) => ({
        ...prevState,
        password: "password is required",
      }));
      temp++;
    }
    if (error.password) {
      temp++;
    }
    if (temp > 0) {
      setloader(false);
      return;
    }
    const bodyFromData = new FormData();

    bodyFromData.append("Id", 0);
    bodyFromData.append("Address", null);
    bodyFromData.append("FirstName", formValues.firstName);
    bodyFromData.append("LastName", formValues.lastName);
    bodyFromData.append("Email", formValues.email);
    bodyFromData.append("Password", formValues.password);
    bodyFromData.append("ProfileImage", null);
    bodyFromData.append("ProfilImage", null);
    bodyFromData.append("Bio", null);
    bodyFromData.append("TwitterLink", null);
    bodyFromData.append("InstagramLink", null);
    bodyFromData.append("RoleId", formValues.role);
    bodyFromData.append("RoleName", null);
    bodyFromData.append("IsAddCollection", false);
    bodyFromData.append("AccountType", 2)
    // bodyFromData.append("AccountStatus", "")
    axios
      .post(API_URL + `api/SuperAdmin/SaveUser`, bodyFromData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
        },
      })
      .then((res) => {
        setloader(false);
        toast.success("Sub Admin Added Successfully");
        navigate("/SubadminManagement");
      })
      .catch((error) => {
        setloader(false);
        if (error.response.data.message) {
          setError((prevState) => ({
            ...prevState,
            email: "Same Email Already Exist",
          }));
          toast.error("Email Already Exist");
        }
      });
  };


  const handlePasswordChange = (e) => {
    const password = e.target.value.trimStart();
    if (!strongPasswordRegex.test(password)) {
      setError((prevState) => ({ ...prevState, password: "Password should be at least 8 characters long and contain at least one letter, one number, and one symbol.", }));

    } else {
      setError((prevState) => ({ ...prevState, password: null }));
    }
  };

  const handleTogglePassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <>
      <CustomLoader isLoading={loader} />
      <Sidebar />
      <main className="dasboard-main">
        <div className="dashboard-inner-pnl">
          <Header />
          <div className="head-inner-panel">
            <div className="full-div">
              {/* BreadCrum */}
              <Breadcrumb>
                <Breadcrumb.Item> Admin Management </Breadcrumb.Item>
                <Breadcrumb.Item href="/SubadminManagement">
                  Subadmin
                </Breadcrumb.Item>
                <Breadcrumb.Item href="#">Add SubAdmin</Breadcrumb.Item>
              </Breadcrumb>
              {/* BreadCrum */}
            </div>
            <div className="full-div">
              <h1>Add SubAdmin</h1>
              <div className="spacer-20"></div>
            </div>
            <div className="full-div">
              <Form
                onSubmit={(e) => handleFormSubmit(e)}
                style={{ maxWidth: "900px" }}
              >
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>FIRST NAME</Form.Label>
                  <Form.Control
                    onChange={() => {
                      setError((prevState) => ({
                        ...prevState,
                        firstName: null,
                      }));
                    }}
                    name="firstName"
                    type="text"
                    placeholder="Enter First name"
                  />
                  {error.firstName && (
                    <p className="error-msg">{error.firstName}</p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>LAST NAME</Form.Label>
                  <Form.Control
                    onChange={() => {
                      setError((prevState) => ({
                        ...prevState,
                        lastName: null,
                      }));
                    }}
                    name="lastName"
                    type="text"
                    placeholder="Enter Last name"
                  />
                  {error.lastName && (
                    <p className="error-msg">{error.lastName}</p>
                  )}
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>EMAIL</Form.Label>
                  <Form.Control
                    onChange={() => {
                      setError((prevState) => ({ ...prevState, email: null }));
                    }}
                    name="email"
                    type="email"
                    placeholder="Enter Email"
                  />
                  {error.email && <p className="error-msg">{error.email}</p>}
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicRole">
                  <Form.Label>Role</Form.Label>
                  <Form.Select
                    onChange={() => {
                      setError((prevState) => ({
                        ...prevState,
                        role: "",
                      }));
                    }}
                    className="form-control"
                    name="role"
                    aria-label="Select role"
                  >
                    <option value="" disabled selected>
                      Choose Role
                    </option>
                    {role?.map((roleData) => (
                      <option value={roleData.id} key={roleData.id}>
                        {roleData.name}
                      </option>
                    ))}
                  </Form.Select>
                  {error.role && <p className="error-msg">{error.role}</p>}
                </Form.Group>
                <Form.Group className="mb-5" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>

                  <div className="button-input-container">
                    <Form.Control
                      className="mb-3"
                      onChange={handlePasswordChange}
                      name="password"
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                    />
                    <Button
                      variant="light"
                      onClick={handleTogglePassword}
                      aria-label={
                        showPassword
                          ? 'Hide password'
                          : 'Show password'
                      }
                    >
                      <FontAwesomeIcon
                        icon={
                          showPassword
                            ? faEyeSlash
                            : faEye
                        }
                      />
                    </Button>
                  </div>
                  {/* <div className="password-toggle">
                    <input
                      type="checkbox"
                      id="show-password-toggle"
                      checked={showPassword}
                      onChange={handleTogglePassword}
                    />
                    <label htmlFor="show-password-toggle">
                      {showPassword ? "Hide password" : "Show password"}
                    </label>
                  </div> */}
                  {error.password && (
                    <p className="error-msg">{error.password}</p>
                  )}
                </Form.Group>

                <ul className="half-list">
                  <li>
                    {loader ? (
                      <>
                        <Button  className="reg-btn w-100 big">
                          <span
                            // className="spinner-grow spinner-grow-sm"
                            className="spinner-grow spinner-grow-sm" id="loading-span"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span className="sr-only">Loading...</span>
                        </Button>
                      </>
                    ) : (
                      <Button className="reg-btn w-100 big" type="submit">
                        Add Subadmin
                      </Button>
                    )}
                  </li>
                  <li>
                    {/* <Link to="/" className='reg-btn w-100 trans big'>Cancel</Link> */}
                    <Button
                      onClick={() => navigate(-1)}
                      className="reg-btn w-100 trans big"
                    >
                      Cancel
                    </Button>
                  </li>
                </ul>
              </Form>
            </div>
          </div>
        </div>

      </main>
    </>
  );
}
export default AddSubadmin;
