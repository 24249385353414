import { createSlice } from '@reduxjs/toolkit';
import localforage from 'localforage';

const initialState = {
  account: {},
};

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    getAccount: (state, { payload }) => {
      state.account = payload;
      localforage.setItem('Account', payload);
    },
  },
});

export const { getAccount } = accountSlice.actions;

export const fetchAccount = () => async (dispatch) => {
  try {
    const account = await localforage.getItem('Account');
    dispatch(getAccount(account));
  } catch (error) {
    // console.error('Error fetching account:', error);
  }
};

export const accountSelector = (state) => state.account;
export default accountSlice.reducer;
