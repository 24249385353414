import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Breadcrumb, Button } from "react-bootstrap";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/header";
import {
  handleGetCollectionCategory,
  handleGetCollectionbyId,
} from "../shared/api";
import axios from "axios";
import { API_URL, MAX_FILE_SIZE_BYTES } from "../constant";
import { toast } from "react-toastify";
import CheckFileSize from "./controllers";
function AddNewCollection() {
  const { id } = useParams();

  const navigate = useNavigate();
  const [colCategory, setcolCategory] = useState([]);
  const [selectedCat, setselectedCat] = useState(null);
  const [loader, setloader] = useState(false);
  const [uploadImages, setuploadImages] = useState({
    coverImage: null,
    logoImage: null,
    featuredImage: null,
  });
  const [collectionData, setcollectionData] = useState({
    logoImage: null,
    featuredImage: null,
    coverImage: null,
    collectionName: null,
    url: null,
    description: null,
  });
  const [erroMessage, seterroMessage] = useState({
    logoErr: null,
    featurdErr: null,
    coverErr: null,
    nameErr: null,
    urlErr: null,
    descriptionErr: null,
  });

  useEffect(() => {
    if (id) {
      handleGetCollectionbyId(id)
        .then((res) => {
          let data = res.data.data;
          setcollectionData({
            logoImage: data.logoImage,
            featuredImage: data.featuredImage,
            coverImage: data.bannerImage,
            collectionName: data.name,
            url: data.url,
            description: data.description,
          });

          setselectedCat(data.categoryId);
        })
        .catch((error) => { });
    }

    handleGetCollectionCategory()
      .then((res) => {
        setcolCategory(res.data.data);

        if (!id) {
          setselectedCat(res.data.data[0].id);
        }
      })
      .catch((error) => { });
  }, []);

  const handleCategoryChange = (event) => {
    const categoryId = event.target.value;
    setselectedCat(categoryId);
  };

  const handleLogoImageUpload = (event) => {
    const file = event.target.files[0];
    // handle the uploaded file
    if (!file.type.match(/^image\/(png|jpeg)$/)) {
      seterroMessage((prevState) => ({
        ...prevState,
        logoErr: "Please upload a PNG or JPG image",
      }));
      // setcollectionData((prevState) => ({
      //     ...prevState,
      //     logoImage: null
      // }))
      return;
    } else {
      
      if(CheckFileSize(file.size)){
        return
      }
      
      setcollectionData((prevState) => ({
        ...prevState,
        logoImage: file,
      }));
      setuploadImages((prev) => ({
        ...prev,
        logoImage: file,
      }));
      seterroMessage((prevState) => ({
        ...prevState,
        logoErr: null,
      }));
    }
  };

  const handleFeaturedImageUpload = (event) => {
    const file = event.target.files[0];
    // handle the uploaded file
    if (!file.type.match(/^image\/(png|jpeg)$/)) {
      seterroMessage((prevState) => ({
        ...prevState,
        featurdErr: "Please upload a PNG or JPG image",
      }));

      return;
    } else {
      if(CheckFileSize(file.size)){
        return
      }
      setcollectionData((prevState) => ({
        ...prevState,
        featuredImage: file,
      }));
      setuploadImages((prev) => ({
        ...prev,
        featuredImage: file,
      }));
      seterroMessage((prevState) => ({
        ...prevState,
        featurdErr: null,
      }));
    }
  };

  const handleCoverImageUpload = (event) => {
    const file = event.target.files[0];
    // handle the uploaded file
    if (!file.type.match(/^image\/(png|jpeg)$/)) {
      seterroMessage((prevState) => ({
        ...prevState,
        coverErr: "Please upload a PNG or JPG image",
      }));

      return;
    } else {
      if(CheckFileSize(file.size)){
        return
      }
      setcollectionData((prevState) => ({
        ...prevState,
        coverImage: file,
      }));
      setuploadImages((prev) => ({
        ...prev,
        coverImage: file,
      }));
      seterroMessage((prevState) => ({
        ...prevState,
        coverErr: null,
      }));
    }
  };

  const handleUrlChange = (event) => {
    const url = event.target.value;

    // validate url format
    if (!url.match(/^(ftp|http|https):\/\/[^ "]+$/)) {
      seterroMessage((prevState) => ({
        ...prevState,
        urlErr: "Please enter a valid URL",
      }));
         setcollectionData((prevState) => ({
        ...prevState,
        url: url,
      }));
    } else {
      seterroMessage((prevState) => ({
        ...prevState,
        urlErr: null,
      }));
      setcollectionData((prevState) => ({
        ...prevState,
        url: url,
      }));
    }
  };

  const handleDescriptionChange = (event) => {
    const description = event.target.value.trimStart();

    // validate description
    if (description.length < 10) {
      seterroMessage((prevState) => ({
        ...prevState,
        descriptionErr: "Description must be at least 10 characters long",
      }));
      setcollectionData((prevState) => ({
        ...prevState,
        description: description,
      }));
    } else {
      seterroMessage((prevState) => ({
        ...prevState,
        descriptionErr: null,
      }));
      setcollectionData((prevState) => ({
        ...prevState,
        description: description,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setloader(true);
    if (
      erroMessage.coverErr ||
      erroMessage.logoErr ||
      erroMessage.featurdErr ||
      erroMessage.nameErr ||
      erroMessage.urlErr ||
      erroMessage.descriptionErr
    ) {
      setloader(false);
      return;
    }

    const bodyFormdata = new FormData();

    bodyFormdata.append("Name", collectionData.collectionName);
    bodyFormdata.append("Url", collectionData.url);
    bodyFormdata.append("Description", collectionData.description);
    bodyFormdata.append("WebsiteLink", null);
    bodyFormdata.append("DiscordLink", null);
    bodyFormdata.append("TwitterLink", null);
    bodyFormdata.append("InstagramLink", null);
    bodyFormdata.append("MediumLink", null);
    bodyFormdata.append("TLink", null);
    bodyFormdata.append("PercentageFee", 0);
    bodyFormdata.append("CategoryId", selectedCat);
    bodyFormdata.append("SensitveContent", false);
    bodyFormdata.append("LogoImage", collectionData.logoImage);
    bodyFormdata.append("FeaturedImage", collectionData.featuredImage);
    bodyFormdata.append("BannerImage", collectionData.coverImage);

    if (id) {
      axios
        .put(
          API_URL + `api/Nft/UpdateNftCollection?collectionId=${id}`,
          bodyFormdata,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("Token")}`,
            },
          }
        )
        .then((res) => {
          setloader(false);

          toast.success("Collection Updated Successfully");
          navigate("/NFTCollectionManagement");
        })
        .catch((error) => {
          setloader(false);
        });
    } else {
      axios
        .post(API_URL + `api/Nft/AddNftCollection`, bodyFormdata, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("Token")}`,
          },
        })
        .then((res) => {
          toast.success("Collection Created Successfully");
          navigate("/NFTCollectionManagement");
        })
        .catch((error) => {
          setloader(false);

          if (error.response.data.message == "Collection already exist") {
            toast.error("Collection Already Exist");
          }
        });
    }
  };
  return (
    <>
      <Sidebar />
      <main className="dasboard-main">
        <div className="dashboard-inner-pnl">
          <Header />
          <div className="head-inner-panel">
            <div className="full-div">
              {/* BreadCrum */}
              <Breadcrumb>
                <Breadcrumb.Item> Admin Management </Breadcrumb.Item>
                <Breadcrumb.Item href="/NFTCollectionManagement">
                  NFT Collection Management
                </Breadcrumb.Item>
                <Breadcrumb.Item href="#">
                  {id ? "Update" : "Add New"} Collection
                </Breadcrumb.Item>
              </Breadcrumb>
              {/* BreadCrum */}
            </div>
            <div className="full-div">
              <h1>{id ? "Update" : "Add New"} Collection</h1>
              <div className="spacer-20"></div>
            </div>
            <div className="full-div">
              <Form
                onSubmit={(e) => handleSubmit(e)}
                style={{ maxWidth: "900px" }}
              >
                <Form.Group className="mb-3 file-uploader-pnl" controlId="logoImage">
                  <Form.Label>Logo Image ( 350 x 350 )</Form.Label>
                  <Form.Control
                    required={!id}
                    style={{ color: "black" }}
                    className="reg-btn big w-100 br-rad"
                    type="file"
                    accept=".png,.jpg,.jpeg"
                    onChange={handleLogoImageUpload}
                  />
                  {erroMessage.logoErr && (
                    <p className="error-msg">{erroMessage.logoErr}</p>
                  )}
                  {(uploadImages.logoImage || collectionData.logoImage) && (
                    <>
                      {uploadImages.logoImage ? (
                        <img
                          style={{ height: "200px", width: "200px", borderRadius: "50%" }}
                          src={URL.createObjectURL(uploadImages.logoImage)}
                          alt="logofig"
                        />
                      ) : (
                        <img
                          style={{ height: "200px", width: "200px", borderRadius: "50%" }}
                          src={`${API_URL}${collectionData.logoImage}`}
                          alt="logofig"
                        />
                      )}
                    </>
                  )}
                </Form.Group>
                <Form.Group className="mb-3 file-uploader-pnl" controlId="featuredImage">
                  <Form.Label>Featured Image ( 600 x 400 )</Form.Label>
                  <Form.Control
                    required={!id}
                    style={{ color: "black" }}
                    className="reg-btn big w-100 br-rad"
                    type="file"
                    accept=".png,.jpg,.jpeg"
                    onChange={handleFeaturedImageUpload}
                  />
                  {erroMessage.featurdErr && (
                    <p className="error-msg">{erroMessage.featurdErr}</p>
                  )}
                  {(uploadImages.featuredImage ||
                    collectionData.featuredImage) && (
                      <>
                        {uploadImages.featuredImage ? (
                          <img
                            style={{ height: "200px", width: "200px" }}
                            src={URL.createObjectURL(uploadImages.featuredImage)}
                            alt="logofig"
                          />
                        ) : (
                          <img
                            style={{ height: "200px", width: "200px" }}
                            src={`${API_URL}${collectionData.featuredImage}`}
                            alt="logofig"
                          />
                        )}
                      </>
                    )}
                </Form.Group>
                <Form.Group className="mb-3 file-uploader-pnl" controlId="coverImage">
                  <Form.Label htmlFor="coverImage" id="coverImageLabel">
                    Cover Image
                  </Form.Label>
                  <Form.Control
                    required={!id}
                    style={{ color: "black" }}
                    className="reg-btn big w-100 br-rad"
                    type="file"
                    accept=".png,.jpg,.jpeg"
                    onChange={handleCoverImageUpload}
                  />
                  {erroMessage.coverErr && (
                    <p className="error-msg">{erroMessage.coverErr}</p>
                  )}
                  {(uploadImages.coverImage || collectionData.coverImage) && (
                    <>
                      {uploadImages.coverImage ? (
                        <img
                          style={{ height: "200px", width: "200px" }}
                          src={URL.createObjectURL(uploadImages.coverImage)}
                          alt="logofig"
                        />
                      ) : (
                        <img
                          style={{ height: "200px", width: "200px" }}
                          src={`${API_URL}${collectionData.coverImage}`}
                          alt="logofig"
                        />
                      )}
                    </>
                  )}
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    onChange={(e) => {
                      setcollectionData((prevState) => ({
                        ...prevState,
                        collectionName: e.target.value.trimStart(),
                      }));
                    }}
                    required
                    value={collectionData.collectionName}
                    type="text"
                    placeholder="Enter Name"
                  />
                  {erroMessage.nameErr && (
                    <p className="error-msg">{erroMessage.nameErr}</p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>URL</Form.Label>
                  <Form.Control
                    required
                    value={collectionData.url}
                    type="text"
                    placeholder="Enter URL"
                    onChange={handleUrlChange}
                  />
                  {erroMessage.urlErr && (
                    <p className="error-msg">{erroMessage.urlErr}</p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Select Category</Form.Label>
                  <Form.Select
                    required
                    value={id && selectedCat}
                    className="form-control"
                    aria-label="Default select example"
                    onChange={(e) => handleCategoryChange(e)}
                  // value={selectedCat}
                  >
                    <option value="" disabled selected>
                      Choose Option
                    </option>

                    {colCategory.map((option) => (
                      <option key={option.name} value={option?.id}>
                        {option.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group
                  className="mb-5"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    required
                    placeholder="e.g. “This is very limited item”"
                    onChange={handleDescriptionChange}
                    value={collectionData.description}
                    maxLength={600}
                  />

                  {erroMessage.descriptionErr && (
                    <p className="error-msg">{erroMessage.descriptionErr}</p>
                  )}
                </Form.Group>
                <ul className="half-list">
                  <li>
                    {loader ? (
                      <>
                        <Button  className="reg-btn w-100 big">
                          <span
                            className="spinner-grow spinner-grow-sm" id="loading-span"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span className="sr-only">Loading...</span>
                        </Button>
                      </>
                    ) : (
                      <Button type="submit" className="reg-btn w-100 big">
                        {" "}
                        {id ? "Update" : "Create"} Collection
                      </Button>
                    )}
                  </li>
                  <li>
                    <div
                      onClick={() => navigate(-1)}
                      className="reg-btn w-100 trans big"
                    >
                      Cancel{" "}
                    </div>
                    {/* <Link to="/" className='reg-btn w-100 trans big'>Cancel</Link> */}
                  </li>
                </ul>
              </Form>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
export default AddNewCollection;
