import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Form, Breadcrumb, Table, Dropdown, Button } from "react-bootstrap";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/header";
import SubAdminScreen from "../Components/SubAdminScreen";
import { Pagination } from "react-pagination-bar";
import OGBXLoader from "../../src/assets/images/Loader.gif"
import { handleGetUserList } from "../shared/api";
import CustomLoader from "../Components/CustomeLoader";
import { useSelector } from "react-redux";
import { rightSelector } from "../redux/rightSlice";
import { Searchbar } from "../Components/Searchbar";
import { accountSelector } from "../redux/accountSlice";

function SubadminManagement() {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const { rightAccess } = useSelector(rightSelector);
  const [subAdmin, setsubAdmin] = useState([]);
  const [loader, setloader] = useState(true);
  const { account } = useSelector(accountSelector);
  const [searchedString, setsearchedString] = useState('');

  useEffect(() => {


    if (account)
      getUserList()
  }, [currentPage, account]);
  const getUserList = () => {
    setloader(true)
    handleGetUserList(currentPage, false, searchedString)
      .then((res) => {
        setsubAdmin(res.data.data.filter((x) => x.id !== account.accountId));
        setloader(false);
        setTotalItems(res.data.totalItems - 1);
      })
      .catch((err) => { setloader(false) });

  }
  useEffect(() => {
    if (searchedString.length == 0 && account) {
      getUserList()
    }
  }, [searchedString, account]);

  const handleSearch = (e) => {
    setloader(true)
    handleGetUserList(1, false, searchedString)
      .then((res) => {
        setloader(false)
        setsubAdmin(res.data.data);
        setTotalItems(res.data.totalItems);
      })
      .catch((err) => { setloader(false) });
  };
  const dataAfterDelete = (id) => {
    setsubAdmin((prev) => [...subAdmin?.filter((x) => x.id !== id)]);
    setTotalItems(totalItems - 1)
  };

  const AccountStatusChange = (id, status) => {
    const updatedUsers = subAdmin.map((user) => {
      if (user.id == id) {
        return { ...user, accountStatus: status }; // Use the spread operator to create a new object with the updated age
      }
      return user; // Return the original object if the id doesn't match
    });

    setsubAdmin(updatedUsers);
  };

  return (
    <>
      {/* <CustomLoader isLoading={loader} /> */}
      <Sidebar />
      <main className="dasboard-main">
        <div className="dashboard-inner-pnl">
          <Header />
          <div className="head-inner-panel">
            <div className="full-div">
              {/* BreadCrum */}
              <Breadcrumb>
                <Breadcrumb.Item> Admin Management </Breadcrumb.Item>
                <Breadcrumb.Item href="#">Subadmin</Breadcrumb.Item>
                <Breadcrumb.Item href="#">Subadmin</Breadcrumb.Item>
              </Breadcrumb>
              {/* BreadCrum */}
            </div>
            <div className="full-div">
              <div className="flex-div-sm">
                <h1>Sub Admin
                  <span
                    style={{
                      color: "#AD79F7",
                      fontSize: "16px",
                      fontWeight: "700",
                      marginLeft: "5px",
                    }}
                  >
                    {totalItems} Users
                  </span>

                </h1>
                {/* <div className="full-div"> */}

                {/* </div> */}
                <ul className="btn-lister">
                  <li>
                    <div className="search-pnl">
                      <Form onSubmit={(e) => e.preventDefault()}>
                        <Searchbar setsearchedString={setsearchedString} handleSearch={handleSearch} searchedString={searchedString} />
                      </Form>
                    </div>
                  </li>
                  {rightAccess?.some(
                    (right) =>
                      right.screenName.toLowerCase() ===
                      "subadmin management" && right.isAdd
                  ) && (
                      <li>
                        <Link to="/AddUserRole" className="reg-btn">
                          <i className="fa fa-plus"></i> Add New Role
                        </Link>
                      </li>
                    )}
                  {rightAccess?.some(
                    (right) =>
                      right.screenName.toLowerCase() ===
                      "subadmin management"
                  ) && (
                      <li>
                        <Link to="/UpdateUserRole" className="reg-btn">
                          <i className="fa fa-plus"></i> Update Role
                        </Link>
                      </li>
                    )}
                  {rightAccess?.some(
                    (right) =>
                      right.screenName.toLowerCase() ===
                      "subadmin management" && right.isAdd
                  ) && (
                      <li>
                        <Link to="/AssignRights" className="reg-btn">
                          <i className="fa fa-plus"></i> Assign Rights
                        </Link>
                      </li>
                    )}
                  {rightAccess?.some(
                    (right) =>
                      right.screenName.toLowerCase() ===
                      "subadmin management" && right.isAdd
                  ) && (
                      <li>
                        <Link to="/AddSubadmin" className="reg-btn">
                          <i className="fa fa-plus"></i> Add SubAdmin
                        </Link>
                      </li>
                    )}
                </ul>
              </div>
            </div>

            <div className="white-div">
              <div className="table-container">
                <div className="table-container-inner">
                  <Table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Password</th>
                        <th>Permission</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    {!loader ? (subAdmin.length > 0 ? (
                      <>
                        <tbody>
                          {subAdmin?.map((subAdminData) => (
                            <SubAdminScreen
                              subAdminData={subAdminData}
                              dataAfterDelete={dataAfterDelete}
                              isPartner={false}
                              AccountStatusChange={AccountStatusChange}
                            />
                          ))}
                        </tbody>
                      </>
                    ) : (
                      <>
                        <tbody
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: "0 auto",
                          }}
                        >
                          No Record Found
                        </tbody>
                      </>
                    )) : <CustomLoader isLoading={loader} />}
                  </Table>
                </div>
              </div>

              <div
                className="full-div text-center pagination-container"
              >
                <Pagination
                  totalItems={totalItems}
                  itemsPerPage={10}
                  currentPage={currentPage}
                  onPageChange={(pageNumber) => {
                    setCurrentPage(pageNumber);
                  }}
                  customClassNames={{
                    rpbItemClassName: "custom-item",
                    rpbItemClassNameActive: "custom-item--active",
                    rpbGoItemClassName: "custom-go-item",
                    rpbItemClassNameDisable: "custom-item--disable",
                    rpbProgressClassName: "custom-progress-bar",
                    rpbRootClassName: "custom-root",
                  }}
                />
                {/* <Link to="/" className='reg-btn big'>View More</Link> */}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
export default SubadminManagement;
