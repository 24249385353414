import React from 'react';

const CleanBG = ({ fill, height = '400', width = '400' }) => {
  return (
    <div style={{ marginLeft: '10%', marginTop: '30px' }}>
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 595.28 841.89"
        height={height}
        width={width}
      >
        <rect
          width="595.28"
          height="841.89"
          fill="#fff"
          strokeWidth="0"
        />
      </svg>
    </div>
  );
};

export default CleanBG;
