import React, { useEffect, useState } from "react";
import { downloadSizes } from "../constant";

function LandscrapeModel({
  show,
  handleClose,
  background,
  title,
  titleColor,
  titleFontSize,
  priceFontSize,
  footer,
  showFooterInput,
  showTC,
  nameFontSize,
  processedImage,
  productDetails,
  titleFontFamily,
  customPrice,
  nameColor,
  priceColor,
  bannerType,
}) {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (show) {
      setVisible(true);
    } else {
      setTimeout(() => setVisible(false), 300);
    }
  }, [show]);

  if (!visible) return null;

  const styles = downloadSizes[bannerType] || {};
  const productDetailStyles = styles.productDetails || {};

  return (
    <div className={`modelContainer ${bannerType}`}>
      <div
        className={`modal-overlay ${show ? "show" : "hide"}`}
        onClick={handleClose}
      >
        <div
          className={`modal-content-live ${show ? "fade-in" : "fade-out"}`}
          onClick={(e) => e.stopPropagation()}
          style={{
            ...styles,
            display: "flex",
            flexDirection: "column",
            backgroundImage: `url(${
              background
                ? background instanceof Blob
                  ? URL.createObjectURL(background)
                  : background
                : ''
            })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div>
            <h1
              style={{
                color: titleColor,
                fontFamily: titleFontFamily,
                fontSize: titleFontSize,
                textAlign: "center",
                marginBottom: "20px",
              }}
              className="titlemodel"
            >
              {title}
            </h1>
          </div>
          <div
            className="product-details-container"
            style={productDetailStyles}
          >
            {productDetails?.map((product, index) => (
              <div
                key={index}
                className="product-details"
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {processedImage && (
                    <img
                      className="img-modal"
                      src={processedImage}
                      alt={product.name}
                      style={{ marginRight: "10px" }} // Spacing between image and price
                    />
                  )}
                  {(customPrice || product.price) && (
                    <p
                      style={{
                        fontSize: priceFontSize,
                        fontFamily: titleFontFamily,
                        color: priceColor,
                        order:1,
                      }}
                      className="price-modal"
                    >
                      ${customPrice || product.price}
                    </p>
                  )}
                </div>
                {product.name && (
                  <p
                    style={{
                      fontSize: nameFontSize,
                      fontFamily: titleFontFamily,
                      color: nameColor,
                      maxWidth: "100%",
                      textAlign: "center", // Centering the name
                      marginTop: "10px", // Space between image/price and name
                    }}
                    className="name-modal"
                  >
                    {product.name}
                  </p>
                )}
              </div>
            ))}
          </div>
          <div
            className="footer-container"
            style={{
              color: titleColor,
              fontFamily: titleFontFamily,
            }}
          >
            {(footer || showFooterInput) && (
              <div className="footer-style-landscape-modal">{footer}</div>
            )}
            {showTC && <div className="T-and-C-landscape-modal">T & C</div>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandscrapeModel;
