import React, { useEffect, useState } from "react";
import { downloadSizes } from "../constant";
import Starssvg from "../SVG/Starssvg";
import CleanBG from "../SVG/CleanBG";

function A4Modl({
  show,
  handleClose,
  background,
  title,
  titleColor,
  titleFontSize,
  priceFontSize,
  regularPrice,
  footer,
  showFooterInput,
  showTC,
  pdfPriceColor,
  description,
  useUploadedBackground,
  nameFontSize,
  processedImage,
  pdfProductDetails,
  titleFontFamily,
  customPrice,
  nameColor,
  pdfbackground,
  bannerType,
}) {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (show) {
      setVisible(true);
    } else {
      setTimeout(() => setVisible(false), 300);
    }
  }, [show]);

  if (!visible) return null;

  const styles = downloadSizes[bannerType] || {};
  const productDetailStyles = styles.productDetails || {};

  return (
    <div className={`modelContainer ${bannerType}`}>
      <div
        className={`modal-overlay ${show ? "show" : "hide"}`}
        onClick={handleClose}
      >
        <div
          className={`modal-content-live ${show ? "fade-in" : "fade-out"}`}
          onClick={(e) => e.stopPropagation()}
          style={{
            
            ...styles,
            backgroundImage: `url(${
              useUploadedBackground && pdfbackground
                ? pdfbackground instanceof Blob
                  ? URL.createObjectURL(pdfbackground)
                  : background
                : CleanBG
            })`,
          }}
        >
          <div>
            <h1
              style={{
                color: titleColor,
                fontFamily: titleFontFamily,
                fontSize: titleFontSize,
                textAlign: "center",
                marginBottom: "20px",
              }}
              className="titlemodel"
            >
              {title}
            </h1>
          </div>
          <div
            className="product-details-container"
            style={productDetailStyles}
          >
            {pdfProductDetails?.map((product, index) => (
              <div key={index}>
                {product.name && (
                  <div
                    className="a44-nameTag-Modal"
                    style={{
                      fontSize: nameFontSize,
                      color: nameColor,
                      fontFamily: titleFontFamily,
                    }}
                  >
                    {product.name}
                  </div>
                )}

                {description && (
                  <div
                    className="a44-description"
                    style={{

                      fontFamily: titleFontFamily,
                    }}
                  >
                    {description}
                  </div>
                )}
                
                <div className="price-container">
                  <Starssvg />
                  {(customPrice || product.price) && (
                    <div
                      className="a44-priceTag"
                      style={{color: pdfPriceColor,}}
                    >
                       <p>Regular Price</p>
                       <div class="price-wrapper">
                        <div class="price-slash"></div>
                        <div class="price">${regularPrice}</div>
                      </div>
                          <p>Only for Today</p>
                      ${customPrice || product.price}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>

          {processedImage && (
            <div>
              <img
                src={processedImage}
                alt="Product"
                className="a44-transparent-image"
              />
            </div>
          )}
          <div
            className="footer-container"
            style={{
              fontFamily: titleFontFamily,
            }}
          >
            {(footer || showFooterInput) && (
              <div className="footer-stylemodel-a4">{footer}</div>
            )}
           
          </div>
        </div>
      </div>
    </div>
  );
}

export default A4Modl;
