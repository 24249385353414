import React from 'react';

const MessageSvg = ({ fill, height = '450', width = '450' }) => {
  return (
    <div style={{ marginLeft: '12%' ,marginTop:'10px'}}>
      <svg width={width} height={height} viewBox="0 0 512 513" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M436.794 75.3761C336.787 -24.6327 174.942 -24.618 74.948 75.3761C26.6198 123.704 0.00431749 187.958 0.00431749 256.301C0.00431749 447.607 201.576 569.445 370.135 485.288L486.063 511.644C501.137 515.069 514.641 501.559 511.216 486.491L484.86 370.564C532.399 275.347 517.204 155.785 436.794 75.3761Z" fill={fill || "#673C74"}/>
      </svg>
    </div>
  );
};

export default MessageSvg;
