import React from "react";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import user from "../assets/images/user.png";
import localforage from "localforage";
import { accountSelector } from "../redux/accountSlice";
import { useSelector } from "react-redux";



function Header() {
  const { account } = useSelector(accountSelector);

  return (
    <>
      <div className="head-info">
        {/* <div className='search-pnl'>
                    <Form>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Control type="search" placeholder="Search" />
                        </Form.Group>
                    </Form>
                </div> */}

        <div className="d-flex justify-content-end">
          <ul className="nav-user-list">
            <li>
              <div className="user-info">
                <div className="img-pnl">
                  <img src={user} alt="user" />
                </div>
                <div className="txt-pnl">
                  <h1>
                    {account?.firstName} {" "} {account?.lastName}

                  </h1>
                </div>
              </div>
            </li>
            {/* <li>
              <Link>
                <i className="fa fa-bell"></i>
              </Link>
            </li> */}
            <li className="web-view">
              <Link
                to="/"
                onClick={() => {
                  localStorage.clear();
                  localforage.clear();
                }}
              >
                <i className="fa fa-arrow-circle-o-right"></i>
              </Link>
            </li>
            <li
              onClick={() => {
                localStorage.clear();
                localforage.clear();
                
              }}
              className="web-view"
            >
              <Link to="/" className="logout">
                <h1>LOGOUT</h1>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
export default Header;
