import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Form, Breadcrumb, Button } from 'react-bootstrap';
import Sidebar from '../Components/Sidebar';
import Header from '../Components/header';
import { API_URL } from '../constant';
import axios from 'axios';
import axiosApi from '../axiosApi';
import { toast } from 'react-toastify';
function AddUserRole() {
    const [newRole, setnewRole] = useState("")
    const [roleErr, setroleErr] = useState("")
    const [loader, setloader] = useState(false)

    const navigate = useNavigate();

    const handleAddRole = (e) => {
        e.preventDefault()
        setloader(true)
        if (!newRole) {
            setroleErr("Please Add New Role")
            setloader(false)
            return
        }


        axiosApi.get(API_URL + `api/SuperAdmin/addrole/${newRole}`
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("Token")}`
                },
            },
        ).then((res) => {
            navigate("/SubadminManagement")
            // setloader(false)
            setloader(false)
            toast.success("New Role Added")
        }).catch((e) => {
            toast.error(e.response?.data?.message)
            setloader(false)
        })


    }
    const handleRoleChange = (event) => {
        const value = event.target.value.trimStart(); // Remove spaces at the beginning and end of the input
        setnewRole(value);
        setroleErr(null)
    };
    return (
        <>
            <Sidebar />
            <main className='dasboard-main'>
                <div className='dashboard-inner-pnl'>
                    <Header />
                    <div className='head-inner-panel'>
                        <div className='full-div'>
                            {/* BreadCrum */}
                            <Breadcrumb>
                                <Breadcrumb.Item> Admin Management  </Breadcrumb.Item>
                                <Breadcrumb.Item href="/SubadminManagement">
                                    Subadmin
                                </Breadcrumb.Item>
                                <Breadcrumb.Item href="#">
                                    Add User Role
                                </Breadcrumb.Item>
                            </Breadcrumb>
                            {/* BreadCrum */}
                        </div>
                        <div className='full-div'>
                            <h1>Add User Role</h1>
                            <div className='spacer-20'></div>
                        </div>
                        <div className='full-div'>
                            <Form onSubmit={(e) => handleAddRole(e)} style={{ maxWidth: '900px' }}>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Role Name</Form.Label>
                                    <Form.Control
                                        onChange={handleRoleChange}
                                        type="text"
                                        placeholder="Enter Role Name"
                                        value={newRole}
                                    />
                                </Form.Group>
                                {roleErr && (<p className='error-msg'>{roleErr}</p>)}
                                <div className='spacer-40'></div>
                                <ul className='half-list'>
                                    <li>

                                        {loader ? (<>
                                            <Button  className='reg-btn w-100 big'>
                                                <span className="spinner-grow spinner-grow-sm" id="loading-span" role="status" aria-hidden="true"></span>
                                                <span className="sr-only">Loading...</span>
                                            </Button>
                                        </>) : (
                                            <Button type='submit' className='reg-btn w-100 big'>
                                                Save
                                            </Button>
                                        )}
                                    </li>
                                    <li>
                                        <Button onClick={() => navigate(-1)} className='reg-btn w-100 trans big'>Cancel

                                        </Button>

                                    </li>
                                </ul>
                            </Form>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}
export default AddUserRole; 