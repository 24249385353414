import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/images/logo.png";
import iconmanagement from "../assets/images/icons/icon-management.png";
import notificationiconwhite from "../assets/images/icons/notification.png";
import notificationcoloricon from "../assets/images/icons/notificationcoloricon.png"
import iconmanagementwhite from "../assets/images/icons/icon-management-white.png";
import iconcollection from "../assets/images/icons/icon-collection.png";
import iconcollectionwhite from "../assets/images/icons/icon-collection-white.png";
import iconpartner from "../assets/images/icons/icon-partner.png";
import iconpartnerwhite from "../assets/images/icons/icon-partner-white.png";
import iconcoupen from "../assets/images/icons/icon-coupen.png";
import iconcoupenwhite from "../assets/images/icons/icon-coupen-white.png";
import iconbannerwhite from "../assets/images/icons/Vector.png";
import iconbanner from "../assets/images/icons/Vector-1.png";



import "../Components/sidebar.scss";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { givenRights, rightSelector } from "../redux/rightSlice";
import localforage from "localforage";
import { accountSelector, fetchAccount, getAccount } from "../redux/accountSlice";
function Sidebar() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { rightAccess } = useSelector(rightSelector);
  const { account } = useSelector(accountSelector);
  const [toggle, settoggle] = useState(false);
  const toggleHandle = (event) => {
    settoggle(!toggle);
  };

  useEffect(() => {
    if (rightAccess?.length === 0) {
      localforage.getItem("Rights").then((value) => {
        dispatch(givenRights(value));
      });
    }
    if (!account.email) {
      dispatch(fetchAccount())
    }

  }, [rightAccess]);
 const UserRole = {
  SUPER_ADMINISTRATOR: "Super Administrator",
};

  return (
    <>
      <div
        className={toggle ? "sidebar active" : "sidebar"}
        onClickOutside={(e) => toggleHandle(e)}
      >
        <button className="toggler" onClick={(e) => toggleHandle(e)}>
          <div>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
        <div className="text-center">
          <a href="/SubadminManagement">
            <img src={logo} alt="Logo" />
          </a>
        </div>
        <ul>

          {rightAccess?.some(
            (right) => right.screenName.toLowerCase() === "coupon management"
          ) && (
              <li
                className={`${location.pathname === "/CouponManagment" ? "active" : ""
                  }`}
              >
                <Link to="/CouponManagment">
                  <div className="img-pnl">
                    <img src={iconcoupen} alt="Icon Coupen" />
                    <img src={iconcoupenwhite} alt="Icon Coupen" />
                  </div>{" "}
                  NFT Coupon Management
                </Link>
              </li>
            )}
          {rightAccess?.some(
            (right) =>
              right.screenName.toLowerCase() === "nft collection management"
          ) && (
              <li
                className={`${location.pathname == "/NFTCollectionManagement" ? "active" : ""
                  }`}
              >
                <Link to="/NFTCollectionManagement">
                  <div className="img-pnl">
                    <img src={iconcollection} alt="Icon Collection" />
                    <img src={iconcollectionwhite} alt="Icon Collection" />
                  </div>{" "}
                  NFT Collection Management
                </Link>
              </li>
            )}
          {rightAccess?.some(
            (right) => right.screenName.toLowerCase() === "partner management"
          ) && (
              <li
                className={`${location.pathname == "/PartnerManagement" ? "active" : ""
                  }`}
              >
                <Link to="/PartnerManagement">
                  <div className="img-pnl">
                    <img src={iconpartner} alt="Icon Partner" />
                    <img src={iconpartnerwhite} alt="Icon Partner" />
                  </div>{" "}
                  Partner Management
                </Link>
              </li>
            )}
          {rightAccess?.some(
            (right) => right.screenName.toLowerCase() === "subadmin management"
          ) && (
              <li
                className={`${location.pathname == "/SubadminManagement" ? "active" : ""
                  }`}
              >
                <Link to="/SubadminManagement">
                  <div className="img-pnl">
                    <img src={iconmanagement} alt="icon  management" />
                    <img src={iconmanagementwhite} alt="icon  management" />
                  </div>{" "}
                  Subadmin Management
                </Link>
              </li>
            )}
{rightAccess?.some(   
            (right) => right.roleName === UserRole.SUPER_ADMINISTRATOR
          ) && (
              <li
                className={`${location.pathname == "/SendNotification" ? "active" : ""
                  }`}
              >
                <Link to="/SendNotification">
                  <div className="img-pnl">
                    <img src={notificationcoloricon} width={22} height={26} alt="icon  management" />
                    <img src={notificationiconwhite} width={22} height={26} alt="icon  management" />
                  </div>{" "}
                  Send Notification
                </Link>
                
              </li>
               )}
               {rightAccess?.some(
            (right) => right.screenName === "Banner Generation"
          ) && (

              <li
                className={`${location.pathname == "/BannerGeneration" ? "active" : ""
                  }`}
              >
                <Link to="/BannerGeneration">
                  <div className="img-pnl">
                    <img src={iconbanner} width={22} height={26} alt="icon  management" />
                    <img src={iconbannerwhite} width={22} height={26} alt="icon  management" />
                  </div>{" "}
                  Banner Generation
                </Link>
                
              </li>
               )}







          <li className="mobile-view">
            <Link to="/">
              <div className="img-pnl">
                <i className="fa fa-arrow-circle-o-right"></i>
              </div>{" "}
              Logout
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
}
export default Sidebar;
